<template>
    <h2
        class="page-404"
    >
        Page not found - 404
    </h2>
</template>

<script>
	export default {
	}
</script>

<style scoped>
.page-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    height: 100px;
}
</style>
